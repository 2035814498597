import React from 'react';
// import { Link } from 'react-router-dom';
import logo from './assets/Maalana-logo.png';
import './App.css';
import insta from './assets/Instagram.png';
import face from './assets/Facebook.png';
import twit from './assets/TwitterX.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* Logo */}
        <img src={logo} alt="Maalana Logo" className="logo" />

        {/* Content Section */}
        <div className="content">
          <div className="subtitle">Bachpan Wala Swaad</div>
          <div className="title">Kuchh Hi Dino Mein</div>
        </div>

        {/* Footer Section */}
        <div className="footer-upper-area">
          <div className="footer">
            {/* Social Media Section */}
            <div className="social">
              Follow us:
              <div className="social-icons">
                <a
                  href="https://www.instagram.com/maalanafoodsofficial/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={insta} alt="Instagram" />
                </a>
                <a
                  href="https://www.facebook.com/maalanafoods"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={face} alt="Facebook" />
                </a>
                <a
                  href="https://www.twitter.com/maalanafoods"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twit} alt="Twitter" />
                </a>
              </div>
            </div>

            {/* Contact Information */}
            <div className="contact">
              For any information, contact us at <br />
              <a href="mailto:maalana2019@gmail.com">maalana2019@gmail.com</a>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
